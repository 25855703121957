import { FAILURE, REQUEST, SUCCESS } from './Constants';

export const createRequestTypes = (base: string, operations: Array<string>) =>
  operations.reduce((acc: any, op: any) => {
    acc[op] = [REQUEST, SUCCESS, FAILURE].reduce(
      (subacc, type) => ({
        ...subacc,
        [type]: `${base}_${op}_${type}`
      }),
      {}
    );
    return acc;
  }, {});

export const action = (type: string, payload = {}) => ({ type, payload });
